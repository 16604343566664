"use client";
import React, { useMemo, useState, useRef, useEffect } from "react";
import { inputFieldProp } from "../../form.interfaces";
import { getFilteredLocs, getSortedLocs } from "@/utils/locAndMap";
import { useLoadScript } from "@react-google-maps/api";
import { RenderAddressSearch } from "@/components/AddressSearch";
import { StyledTextField } from "@/components/Form/partials/FormStyles";

export const RenderAddressInputField: React.FC<inputFieldProp> = ({
	keyName,
	field,
	setState,
	context,
	args,
	mobileView,
}) => {
  const MAX_LOCS = 5;
	const [fieldState, setFieldState] = useState<any | null>(null);
	const [selectedLocIndex, setSelectedLocIndex] = useState<number | null>(null);
	const [visibleCount, setVisibleCount] = useState<number>(3);
	const containerRef = useRef<HTMLDivElement>(null);
	const libraries = useMemo(() => ["places"], []);
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: "AIzaSyAohftA6_ox8J_cauamH33mBcFjT-AnncE",
		libraries: libraries as any,
	});
	const { blacklist, whitelist, market, category } = field.data.filters;

	const filteredLocs = useMemo(
		() =>
			getFilteredLocs(
				context?.locations?.data,
				category,
				market,
				fieldState,
				blacklist ? blacklist.replaceAll(" ", "").split(",") : [],
				whitelist ? whitelist.replaceAll(" ", "").split(",") : []
			),
		[context?.locations?.data, field.data.filters, fieldState]
	);

	const alwaysShowSelector = filteredLocs.length <= 3;
	const onlyOneLoc = filteredLocs.length === 1;

	const preSelectedLoc = onlyOneLoc
		? filteredLocs[0]
		: getFilteredLocs(context?.locations?.data, category, market).find(
				(x) =>
					x.title.trim().toLowerCase() ===
						decodeURIComponent(args?.query?.location)
							.replace(/&#8211;/g, "-")
							.trim()
							.toLowerCase() && !x.disabled
		  );

	useEffect(() => {
		if (preSelectedLoc) {
			setFieldState(preSelectedLoc);
		}
	}, []);

	useEffect(() => {
		if (preSelectedLoc) {
			setSelectedLocIndex(0);
			setState(keyName, preSelectedLoc.abbreviation || null);
		}
	}, []);

	useEffect(() => {
		if (!mobileView && containerRef.current && visibleCount === 3) {
			containerRef.current.style.maxHeight = containerRef.current.clientHeight + "px";
		}
	}, [containerRef.current, preSelectedLoc]);

	useEffect(() => {
		// Reset selection when the user selects another location on the Address Search
		if (fieldState) {
			if (!fieldState?.title || fieldState?.title !== preSelectedLoc?.title) {
				// Checks if the fieldState is not the preSeletedLoc from the query
				setSelectedLocIndex(null);
				setState(keyName, null);
			}
			setVisibleCount(3);
		}
	}, [fieldState]);

	useEffect(() => {
		if (containerRef.current) {
			if (mobileView) {
				containerRef.current.nextElementSibling.scrollIntoView({
					behavior: "smooth",
					block: mobileView ? "end" : undefined,
				});
			} else {
				containerRef.current.scrollTo({
					top: containerRef.current.scrollHeight,
					behavior: "smooth",
				});
			}
		}
	}, [visibleCount]);

	const handleShowMore = () => {
		setVisibleCount((prevCount) => prevCount < MAX_LOCS ? prevCount + 2 : MAX_LOCS);
	};

	const handleSelectLocation = (index: number, sortedLocs: any) => {
		setSelectedLocIndex(index);
		setState(keyName, sortedLocs[index]?.abbreviation || null);
	};

	const LocationSelector: React.FC = () => {
		const sortedLocs = getSortedLocs(filteredLocs);

		const NoVacancies: React.FC = () => {
			const noVacanciesTranslation = {
				en: "No vacancies",
				nl: "Geen vacatures",
				de: "Keine Angebote",
				fr: "Aucune offre",
			};
			return (
				<p className="m-0 text-base leading-6 text-red1">
					{noVacanciesTranslation[market]}
				</p>
			);
		};

		const Waitlist: React.FC = () => {
			const waitlistTranslation = {
				en: "No vacancies, you're on the waitlist",
				nl: "Geen vacatures, je komt op de wachtlijst",
				de: "Keine Angebote, du bist auf der Warteliste",
				fr: "Aucune offre, vous êtes sur la liste d'attente",
			};
			return (
				<p className="m-0 text-base leading-6 text-red1">
					{waitlistTranslation[market]}
				</p>
			);
		};

		const ManyVacancies: React.FC = () => {
			const manyVacanciesTranslation = {
				en: "🔥 Many vacancies",
				nl: "🔥 Veel mensen gezocht!",
				de: "🔥 Viele freie Stellen!",
				fr: "🔥 Plusieurs postes libres sur ce site",
			};
			return (
				<p className="m-0 text-base leading-6 text-green2">
					{manyVacanciesTranslation[market]}
				</p>
			);
		};

    const DistanceMap = {
      en: " km from your location",
      nl: " km van jouw locatie",
      de: " km von dir",
      fr: " KM de ta position"
    }

		const Distance: React.FC<{ distance: number }> = ({ distance }) => (
			<p className="m-0 text-base leading-6 text-grey3">
				<span className={"font-semibold"}>{distance.toFixed(1)}</span>{DistanceMap[args.locale.language]}
			</p>
		);

		const LocationContent: React.FC<{ location: any }> = ({ location }) => (
			<div className="flex-1">
				<h3 className="m-0 text-lg text-black">{location.title}</h3>
				<p className="m-0 text-grey3">{location.address1}</p>
				<p className="m-0 text-grey3">{location.address2}</p>
				{location.dist && location.dist > 0 && !isNaN(location.dist) ? (
					<Distance distance={location.dist} />
				) : null}
				{location.disabled && <NoVacancies />}
				{!location.disabled && location.watchlist && <Waitlist />}
				{!location.disabled && !location.watchlist && location.manyVacancies && <ManyVacancies />}
			</div>
		);

		const LocationCard: React.FC<{ location: any; index: number }> = ({
			location,
			index,
		}) => {
			const isSelected = selectedLocIndex === index;

			return (
				<div
					className={`flex px-3 py-4 text-black rounded border bg-grey0 cursor-pointer ${
						isSelected ? "border-green1" : "border-grey1"
					}`}
					onClick={() =>
						!location.disabled &&
						handleSelectLocation(index, sortedLocs)
					}
				>
					<RadioButton
						isSelected={isSelected}
						isDisabled={location.disabled}
					/>
					<LocationContent location={location} />
				</div>
			);
		};

		const RadioButton: React.FC<{
			isSelected: boolean;
			isDisabled: boolean;
		}> = ({ isSelected, isDisabled }) => (
			<div className="flex py-1 mr-4">
				<input
					type="radio"
					name="location"
					checked={isSelected}
					className="hidden"
					readOnly
				/>
				<div
					className={`w-5 h-5 rounded-full border bg-white flex items-center justify-center 
            ${isSelected ? "border-green1 bg-green1" : "border-grey2"}`}
				>
					<div
						className={`w-3.5 h-3.5 rounded-full ${isDisabled ? "bg-grey2" : "bg-green1"} 
            ${isSelected || isDisabled ? "opacity-1" : "opacity-0"} `}
					></div>
				</div>
			</div>
		);

		const ShowMoreButton: React.FC = () => {
			const showMoreTranslation = {
				en: "Show more",
				nl: "Toon meer",
				de: "Mehr anzeigen",
				fr: "Montrer plus",
			};
			return (
				<div
					className="mt-4 text-center text-grey4 underline cursor-pointer"
					onClick={handleShowMore}
				>
					{showMoreTranslation[market]}
				</div>
			);
		};

		return (
			<>
				<div
					ref={containerRef}
					className={`p-0 overflow-y-auto flex gap-4 flex-col ${onlyOneLoc ? "mt-2" : "mt-4"}`}
				>
					{sortedLocs.map(
						(location, index) =>
							index < visibleCount && (
								<LocationCard
									key={index}
									location={location}
									index={index}
								/>
							)
					)}
				</div>
				{visibleCount < MAX_LOCS && <ShowMoreButton />}
			</>
		);
	};

	const ValidationInput = () => {
		const validationTranslation = {
			en: "Please choose a location!",
			nl: "Selecteer een locatie!",
			de: "Bitte eine Adresse auswaehlen!",
			fr: "Veuillez choisir une adresse!",
		};

		return (
			<StyledTextField
				style={{ position: "absolute", opacity: 0 }}
				required={!!field.data.validation?.required}
				type="text_input"
				size="small"
				value={selectedLocIndex}
				onFocus={(e) => { e.target.blur() }}
				onInvalid={(e) =>
					e.target.setCustomValidity(field.data?.validation?.required?.validation_text || validationTranslation[market])
				}
			/>
		);
	};

	if (!isLoaded) {
		return (
			<StyledTextField
				label="Loading Address Search..."
				type="text_input"
				size="small"
				fullWidth
				disabled
			/>
		);
	}

	const searchTranslation = {
		en: "Search",
		nl: "Zoek",
		de: "Suchen",
		fr: "Chercher",
	};

  const SelectTextMap = {
    en: "Select your desired working location",
    nl: "Kies je favoriete werkplek",
    de: "Wähle deinen gewünschten Arbeitsort",
    fr: "Choisis ton lieu de travail préféré"
  }
  
	return (
		<>
			{!onlyOneLoc ? (
				<>
					<ValidationInput />
					<RenderAddressSearch
						locations={filteredLocs}
						label={field.data.label}
						variant="form"
						initialValue={preSelectedLoc?.title || null}
						searchBtn={searchTranslation[args.locale.language]}
						states={{
							selectedPlace: {
								value: fieldState,
								set: setFieldState,
							},
						}}
					/>
				</>
			) : (
				<div className={"text-grey4"}>{field.data.label}</div>
			)}
			<div
				className={`overflow-hidden transition-all duration-700 ease-in-out origin-top ${
					fieldState || alwaysShowSelector ? "max-h-none md:max-h-screen" : "max-h-0"
				}`}
			>
        <p className="mt-4 -mb-2">{SelectTextMap[args.locale.language]}</p>
				{(fieldState || alwaysShowSelector) && LocationSelector()}
			</div>
		</>
	);
};
