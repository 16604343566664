"use client";
import React, { useRef, useEffect } from "react";
import { SegmentSelectorProps } from "../types";
import { Link } from "@/shared/components/Link";
import Flicking from "@egjs/react-flicking";

export const SegmentSelector = ({ segments, selectedSegment, lang, mobileView }: SegmentSelectorProps) => {
	const flickingRef = useRef(null);

	const renderSegments = () => {
		return segments.map((segment) => (
			<Link
				key={segment.segment_type}
				className={`px-3 py-1 md:py-1.5 rounded text-sm transition-colors mr-3 ${
					selectedSegment === segment.segment_type
						? "bg-green7 text-white"
						: "bg-grey0 border-grey1 border text-grey4 hover:bg-grey2 hover:border-grey2"
				}`}
				href={`/${lang}/recipes/?tab=${segment.segment_type}`}
				prefetch={true}
			>
				<span className="leading-none align-text-bottom">
					{segment.segment_name}
				</span>
			</Link>
		));
	};

	// Scroll to the selected segment initially and whenever it changes
	useEffect(() => {
		if (flickingRef.current) {
			const index = segments.findIndex((segment) => segment.segment_type === selectedSegment);
			if (index !== -1) {
				flickingRef.current.moveTo(index, 0); // Set duration to 0 for instant focus on initial load
			}
		}
	}, [selectedSegment, segments]);

	return (
		<div className="mb-4 md:mb-8 my-auto">
			{mobileView ? (
				<Flicking
					key={selectedSegment}
					ref={flickingRef}
					align="prev"
					gap={10}
					circular={false}
					bound={true}
					useFindDOMNode={true}
				>
					<React.Fragment key={selectedSegment}>
						{renderSegments()}
					</React.Fragment>
				</Flicking>
			) : renderSegments()}
		</div>
	);
};

SegmentSelector.displayName = "SegmentSelector";
