export type parseUrlStruct = {
	urlModule: string;
	locale?: string;
	pageSlug: string;
	additionQueries?: string;
};

const localeList = ["nl", "de", "fr", "nl-en", "de-en", "fr-en"];
const ModuleMap: Record<string, string> = {
	careers: "picnic-careers",
};

const getModuleFromUrl = (slugs: string[]) => {
	let urlModule = undefined;
	slugs.forEach((slug) => {
		if (Object.keys(ModuleMap).includes(slug)) {
			urlModule = ModuleMap[slug];
		}
	});

	return urlModule;
};

const getLocaleFromUrl = (slugs: string[]) => {
	let locale = undefined;
	slugs.some((slug) => {
		if (localeList.includes(slug)) {
			locale = slug;
		}
	});

	return locale;
};

const getPageSlugFromUrl = (slugs: string[], urlModule, locale) => {
	let pageURL = undefined;
	const moduleKey = Object.keys(ModuleMap).find(
		(key) => ModuleMap[key] === urlModule
	);
	const index =
		slugs.indexOf(locale) > slugs.indexOf(moduleKey)
			? slugs.indexOf(locale)
			: slugs.indexOf(moduleKey);
	if (slugs.length >= index + 1) {
		pageURL = slugs[index + 1];
	}
	return pageURL;
};

const getAdditionalQueriesFromUrl = (slugs: string[], page) => {
	let additionalQueries = "";
	const index = slugs.indexOf(page);
	if (slugs.length >= index + 1) {
		const additionalQueryArray = slugs.slice(index + 1);
		additionalQueries = "?resource_id=";
		additionalQueryArray.forEach((query) => {
			additionalQueries += `${query},`;
		});
	}
	return additionalQueries;
};

export function parseURL(slug: Array<string>): parseUrlStruct {
	const urlModule = getModuleFromUrl(slug);
	const locale = getLocaleFromUrl(slug);
	const pageSlug = getPageSlugFromUrl(slug, urlModule, locale);
	const additionalQueries = getAdditionalQueriesFromUrl(slug, pageSlug);

	return {
		urlModule: urlModule ? urlModule : "picnic-web",
		locale: locale,
		pageSlug: pageSlug ? pageSlug : "home",
		additionQueries: additionalQueries,
	};
}

export const replaceLocaleInSlug = (
	slug: string[],
	locale: string,
	newLocale: string
) => {
	const index = slug.indexOf(locale);
	slug[index] = newLocale;

	return slug;
};

export const getJobId = () => {
	const pathSegments = window.location.pathname.split("/");
	return pathSegments
		.find((segment) => segment.startsWith("J") && segment.length === 8)
		?.slice(1);
};

export const getUTMParams = () => {
	const searchParams = new URLSearchParams(window.location.search);
	return {
		utm_source: searchParams.get("utm_source") || "",
		utm_medium: searchParams.get("utm_medium") || "",
		utm_campaign: searchParams.get("utm_campaign") || "",
		utm_term: searchParams.get("utm_term") || "",
		utm_content: searchParams.get("utm_content") || "",
	};
};
