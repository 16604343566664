"use client";
import React from "react";
import { useScrollResize } from "../partials2/scroll";
import RenderMediaComponent from "@/shared/components/Media";

const RecipeImage = ({ recipe, lang, mobileView }: any) => {
    const imageUrl = recipe.images[0];

    const initialHeight = 500;
    const minHeight = 416;
    const imageHeight = useScrollResize(initialHeight, minHeight);

	return (
        <div className={"md:w-[55%]"} style={{height: mobileView ? `${imageHeight}px` : "auto"}}>
            <RenderMediaComponent data={{
                    "media_src": {
                        "desktop": {
                            "src": imageUrl,
                            "type": "image",
                            "meta": {
                                "alt": recipe.name,
                                "blurhash": "data:image/webp;base64,UklGRjAAAABXRUJQVlA4ICQAAACQAQCdASoEAAIAAgA0JZwAAudYugAA/vShPTMGsRJli3i4AAA="
                            }
                        }
                    },
                    "styling": {
                        "priority": true
                    }
            }} mobileView={mobileView}/>
        </div>
    );
};

export default RecipeImage;
