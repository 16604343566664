"use client";
import React, { useState, useRef, useEffect } from "react";
import { WhatsApp } from "@mui/icons-material";
import { localeRouteInterface } from "@/utils/MarketandLanguage";
import { translations } from "./partials/text";
import FlagPhoneInput from "./partials/phone-input";
import { getJobId, getUTMParams } from "@/shared/utils/parseURL";

type Props = {
	pageArgs: localeRouteInterface;
	enabled: boolean;
};

const WhatsAppForm = ({ pageArgs, enabled }: Props) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [defaultValue, setDefaultValue] = useState("");

	const buttonRef = useRef<HTMLButtonElement>(null);
	const searchParams = new URLSearchParams(window.location.search);

	if (!enabled && !searchParams?.get("tests")?.includes("whatsapp")) {
		return null;
	}

	useEffect(() => {
		const handleScroll = () => setIsVisible(window.scrollY > 800);
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const form = e.currentTarget;

		const phoneNumber = form.querySelector('input[type="tel"]')?.value;
		setIsLoading(true);
		setDefaultValue(phoneNumber);

		const body = {
			phoneNumber: phoneNumber.replace(/\s/g, ""),
			jobId: getJobId(),
			fields: getUTMParams(),
		};

		if (phoneNumber.length < 5) {
			setIsLoading(false);
			return;
		}
		try {
			const response = await fetch("/api/forms/submission/whatsapp", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(body),
			});

			if (response.ok) {
				form.reset();
				setIsSubmitted(true);
			} else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleButtonClick = () => {
		if (buttonRef.current) {
			buttonRef.current.style.transition =
				"width 0.5s ease-in-out, opacity 0.1s ease-out 0.250s";
			buttonRef.current.style.width = "0";
			buttonRef.current.style.padding = "0";
			buttonRef.current.style.opacity = "0";
			buttonRef.current.style.pointerEvents = "none";
		}
	};

	const lang = pageArgs?.locale?.language || "en";

	const WhatsAppButton = () => {
		return !isSubmitted ? (
			<div>
				<div className="text-center font-semibold mb-2">
					{translations.cta[lang]}
				</div>
				<div className="mx-auto w-3/4 relative">
					{!isLoading && !defaultValue && (
						<button
							ref={buttonRef}
							onClick={handleButtonClick}
							className="right-0 flex absolute w-full h-full bg-green6 text-white font-bold py-2 px-4 rounded-full hover:bg-green1 transition whitespace-nowrap overflow-hidden z-20"
						>
							<span className="flex items-center text-xs mx-auto text-white hover:text-green6">
								<WhatsApp className="mr-2" />
								{translations.applyViaWhatsApp[lang]}
							</span>
						</button>
					)}
					<form onSubmit={handleSubmit} className="w-full z-10 flex">
						<div className="flex w-full">
							<div style={{ width: "65%" }}>
								<FlagPhoneInput
									placeholder={
										translations.enterPhoneNumber[lang]
									}
									defaultCountry={lang}
									defaultValue={defaultValue}
								/>
							</div>
							<button
								type="submit"
								id="whatsapp-submit"
								className={`bg-green6 text-white font-bold py-2 px-4 rounded-r-full hover:bg-green1 transition-all duration-300 ease-in-out ${
									isLoading ? "animate-pulse" : ""
								}`}
								disabled={isLoading}
								style={{ width: "35%" }}
							>
								{isLoading
									? translations.submitting[lang]
									: translations.submit[lang]}
							</button>
						</div>
					</form>
				</div>
			</div>
		) : (
			<div className="text-center text-green6 font-bold">
				{translations.thanksForApplying[lang]} <br />
				{translations.contactSoon[lang]}
			</div>
		);
	};

	return (
		<div
			className={`fixed bottom-0 py-4 bg-white z-40 shadow w-full transition-transform duration-500 ${
				isVisible ? "translate-y-0" : "translate-y-full"
			}`}
		>
			<WhatsAppButton />
		</div>
	);
};

export default WhatsAppForm;
