import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface PhoneInputProps {
	placeholder?: string;
	defaultCountry?: string;
	error?: string;
	defaultValue?: string;
}

const FlagPhoneInput: React.FC<PhoneInputProps> = React.memo(
	({ placeholder, defaultCountry = "none", error, defaultValue }) => {
		const [phoneNumber, setPhoneNumber] = useState(defaultValue || "");

		const countryMap = {
			none: "",
			en: "UK",
			de: "DE",
			nl: "NL",
			fr: "FR",
		};

		return (
			<div className="relative w-full">
				<PhoneInput
					international
					countryCallingCodeEditable={true}
					value={phoneNumber}
					onChange={setPhoneNumber}
					defaultCountry={
						countryMap[defaultCountry as keyof typeof countryMap]
					}
					placeholder={placeholder || "Enter phone number"}
					autoComplete="off"
					data-lpignore="true"
					className="flex items-center border border-green6 rounded-l-full p-2 [&_.PhoneInputInput:focus]:outline-none"
				/>
				{error && <p className="text-red1 text-sm mt-1">{error}</p>}
			</div>
		);
	}
);

export default FlagPhoneInput;
